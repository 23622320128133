// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-empresas-js": () => import("./../../../src/pages/empresas.js" /* webpackChunkName: "component---src-pages-empresas-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-individuos-js": () => import("./../../../src/pages/individuos.js" /* webpackChunkName: "component---src-pages-individuos-js" */),
  "component---src-pages-legales-js": () => import("./../../../src/pages/legales.js" /* webpackChunkName: "component---src-pages-legales-js" */)
}

